
::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.15);
}

body {
    font-family: 'Montserrat';
}

.darkest-keepler {
    background-color: #0F1015;
}

.darker-keepler {
    background-color: #1D1F2A;
}

.text-gray-keepler {
    color: #97999B;
}

.dark-keepler {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.b-white b{
    color: #FFF;
}

.sidebar-keepler {
    height: 100%;
    width: 300px;
    overflow-y: auto;
    position: fixed;
    padding: 30px 30px 200px 30px;
    border-radius: 0px 5px 5px 0px;
}

.main-content-keepler {
    margin-left: 325px;
    /* height: 100%; */
    z-index: 0;
    display: flex;
    overflow-y: auto;
    align-items: center;
    /* justify-content: center; */
}

.message-box-keepler {
    margin-left: 300px;
    padding: 16px 10px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
    .sidebar-keepler {
        width: 100%;
        height: auto;
        position: relative;
        padding: 30px;
    }

    .main-content-keepler {
        margin-left: 0;
    }

    .message-box-keepler {
        margin-left: 0;
    }
}

@media screen and (max-width: 1200px) {
    .message-box-keepler {
        margin-left: 0;
        padding: 16px;
    }
}

label.button-keepler {
    padding: 8px;
    font-size: 0.875em;
    text-align: center;
    vertical-align: middle;
    min-width: 100%;
    margin: 20px 0px;
    background-color: #0F1015;
    color: #FFF;
    font-weight: bold;
    border: 0px;
    height: 40px;
    border-bottom: 3px solid #4298B5;
    cursor: pointer;
}

label.button-keepler:hover {
    background-color: #4298B5;
    border-bottom: 3px solid #0F1015;
}

a.action-keepler:hover {
    cursor: pointer;
    color: #FFF;
}

/* Customize the label (the container) */
.checkbox-keepler {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 0.875em;
    color: #97999B;
}

/* Hide the browser's default checkbox */
.checkbox-keepler input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    margin-top: 4px;
    height: 15px;
    width: 15px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-keepler:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-keepler input:checked~.checkmark {
    background-color: #4298B5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-keepler input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-keepler .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}